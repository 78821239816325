export const defaultState = () => {};

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    sendResetPasswordLink(vuexContext, email) {
        return this.$axios.post('/auth/forgot-password', { email });
    },

    checkPasswordResetToken(vuexContext, token) {
        return this.$axios.get(`/auth/reset-password/${token}`);
    },

    resetPassword(vuexContext, { password, passwordConfirmation, token }) {
        return this.$axios.post(`/auth/reset-password/${token}`, {
            password,
            passwordConfirmation
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
