var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"b_left_sidebar",class:{ 'show-sidebar': _vm.isSidebarOpen }},[_c('div',{staticClass:"sidebar_head"},[_c('div',{staticClass:"head_inner flex ai_center jc_between"},[_c('div',{staticClass:"logo_block"},[_c('img',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:('logo_text.png'),expression:"'logo_text.png'",arg:"assets"}]})]),_vm._v(" "),_c('div',{staticClass:"close_wrapper"},[_c('button',{staticClass:"btn_reset btn_close",attrs:{"type":"button"},on:{"click":_vm.toggleSidebar}})])])]),_vm._v(" "),_c('div',{staticClass:"sidebar_inner"},[(_vm.$auth.user)?_c('div',{staticClass:"nav_menu"},[_c('div',{staticClass:"menu_item"},[_c('nuxt-link',{staticClass:"menu_link",attrs:{"to":"/billings"}},[_c('span',{staticClass:"icon"},[_c('span',{staticClass:"icon_static"},[_c('img',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                    'icon_billings_static.svg'
                                ),expression:"\n                                    'icon_billings_static.svg'\n                                ",arg:"assets"}]})]),_vm._v(" "),_c('span',{staticClass:"icon_hover"},[_c('img',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                    'icon_billings_hover.svg'
                                ),expression:"\n                                    'icon_billings_hover.svg'\n                                ",arg:"assets"}]})])]),_vm._v(" "),_c('span',{staticClass:"title"},[_vm._v("Billings")])])],1),_vm._v(" "),_c('div',{staticClass:"menu_item"},[_c('nuxt-link',{staticClass:"menu_link",attrs:{"to":"/usage"}},[_c('span',{staticClass:"icon"},[_c('span',{staticClass:"icon_static"},[_c('img',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                    'icon_trade_usage_static.svg'
                                ),expression:"\n                                    'icon_trade_usage_static.svg'\n                                ",arg:"assets"}]})]),_vm._v(" "),_c('span',{staticClass:"icon_hover"},[_c('img',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                    'icon_trade_usage_hover.svg'
                                ),expression:"\n                                    'icon_trade_usage_hover.svg'\n                                ",arg:"assets"}]})])]),_vm._v(" "),_c('span',{staticClass:"title"},[_vm._v("Usage")])])],1),_vm._v(" "),_c('div',{staticClass:"menu_item"},[_c('nuxt-link',{staticClass:"menu_link",attrs:{"to":"/contact-us"}},[_c('span',{staticClass:"icon"},[_c('span',{staticClass:"icon_static"},[_c('img',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                    'icon_contact_us_static.svg'
                                ),expression:"\n                                    'icon_contact_us_static.svg'\n                                ",arg:"assets"}]})]),_vm._v(" "),_c('span',{staticClass:"icon_hover"},[_c('img',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                    'icon_contact_us_hover.svg'
                                ),expression:"\n                                    'icon_contact_us_hover.svg'\n                                ",arg:"assets"}]})])]),_vm._v(" "),_c('span',{staticClass:"title"},[_vm._v("Contact us")])])],1),_vm._v(" "),_c('div',{staticClass:"menu_item"},[_c('nuxt-link',{staticClass:"menu_link",attrs:{"to":"/payments"}},[_c('span',{staticClass:"icon"},[_c('span',{staticClass:"icon_static"},[_c('img',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                    'icon_payments_static.svg'
                                ),expression:"\n                                    'icon_payments_static.svg'\n                                ",arg:"assets"}]})]),_vm._v(" "),_c('span',{staticClass:"icon_hover"},[_c('img',{directives:[{name:"full-image-path",rawName:"v-full-image-path:assets",value:(
                                    'icon_payments_hover.svg'
                                ),expression:"\n                                    'icon_payments_hover.svg'\n                                ",arg:"assets"}]})])]),_vm._v(" "),_c('span',{staticClass:"title"},[_vm._v("Payments")])])],1)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }