import cookie from 'cookie';

const TOKEN_KEY = 'auth._token.local';

export default function({ $axios, req, redirect, app }) {
    $axios.onError(error => {
        if (error.response && error.response.status === 401) {
            if (process.server) {
                document.cookie = `${TOKEN_KEY}=''; path=/`;

                return redirect('/login');
            }

            if (app.context && app.context.route) {
                const nonRedirectRoutes = [
                    'login',
                    'forget-password',
                    'reset-password',
                    'set-password'
                ];

                const {
                    route: { name: currentRouteName }
                } = app.context;

                if (
                    !nonRedirectRoutes.find(route =>
                        currentRouteName.startsWith(route)
                    )
                ) {
                    return location.reload();
                }
            }
        }
    });

    $axios.onRequest(config => {
        let CLUID;

        if (process.server && req.headers.cookie) {
            const cookies = cookie.parse(req.headers.cookie);

            CLUID = cookies[TOKEN_KEY];

            $axios.defaults.headers.Cookie = req.headers.cookie;
        } else if (process.client) {
            CLUID = localStorage[TOKEN_KEY] || '';
        }

        if (CLUID) {
            config.headers.cluid = CLUID;
        }
    });
}
