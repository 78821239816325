export const defaultState = () => {
    return {
        form: {}
    };
};

export const state = () => defaultState();

export const getters = {
    form: state => state.form
};

export const mutations = {
    SET_PAYMENT_FORM(state, form) {
        state.form = form;
    }
};

export const actions = {
    savePaymentForm({ commit }, form) {
        commit('SET_PAYMENT_FORM', form);
    },

    index(
        ctx,
        {
            currentPage = 1,
            perPage = 12,
            search = '',
            sortBy = ['createdAt:DESC'],
            filters = null
        } = {}
    ) {
        const params = {
            page: currentPage,
            perPage,
            q: search,
            sortBy
        };

        if (filters) {
            params.filters = JSON.stringify(filters);
        }

        return this.$axios.$get('/payments', {
            params
        });
    },

    getCards() {
        return this.$axios.$get('/payments/cards');
    },

    create(ctx, data) {
        return this.$axios.$post('/payments', data);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
