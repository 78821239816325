export const defaultState = () => ({
    isStripeInitialized: false
});

export const state = () => defaultState();

export const getters = {
    isStripeInitialized: state => state.isStripeInitialized
};

export const mutations = {
    SET_IS_STRIPE_INITIALIZED(state, value) {
        state.isStripeInitialized = value;
    }
};

export const actions = {
    initStripe({ getters, commit }) {
        const scriptId = 'stripeScript';

        const isScriptAlreadyAdded = document.getElementById(scriptId);

        if (isScriptAlreadyAdded || getters.isInitialized) {
            return;
        }

        const script = document.createElement('script');
        script.setAttribute('src', 'https://js.stripe.com/v3/');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('async', 'async');
        script.setAttribute('id', scriptId);

        script.addEventListener('load', () => {
            commit('SET_IS_STRIPE_INITIALIZED', true);
        });

        script.addEventListener('error', () => {
            console.error('Stripe script error.');
        });

        document.head.appendChild(script);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
