const { upperFirst } = require('lodash');

class GetValidationErrorMessageHandler {
    handle(validation, serverErrors, key, index) {
        const item = this.#getItem(validation, key, index);
        const serverError = this.#getServerError(serverErrors, key, index);

        if (serverError) {
            return serverError;
        }

        if (!item || !item.$error) {
            return '';
        }

        const { $params } = item;

        for (const key in $params) {
            if (!item[key]) {
                return this.#getError(key, item.$params);
            }
        }

        return 'Something is wrong... 🤔';
    }

    #getItem(validation, key, index) {
        if (isNaN(index)) {
            return validation[key];
        }

        return validation.$each[index][key];
    }

    #getServerError(serverErrors, key, index) {
        if (!isNaN(index)) {
            // @TODO
            return '';
        }

        if (serverErrors.length) {
            const errorItem = serverErrors.find(error => error.param === key);

            if (errorItem) {
                return errorItem.message;
            }
        }

        return '';
    }

    #getError(key, params = {}) {
        const messagesTable = {
            required: 'Should not be empty!',
            isFormatValid: 'Wrong format!',
            email: 'Email address is not valid!',
            atLeastOneItem: 'Please add at least one item!',
            isFullName:
                'Full name must contain two words with at least two letters each.',
            maxElements: 'You have chosen too many elements!',
            size: 'File is too large!',
            integer: 'Must be an integer!',
            maxLength: `Text length must be less than or equal ${params?.maxLength?.max}.`,
            minValue: `Value must be greater than or equal ${params?.minValue?.min}.`,
            maxValue: `Value must be less than or equal ${params?.maxValue?.max}.`,
            minLength: `Cannot contain less than ${params?.minLength?.min} characters.`,
            containsDigits: 'Must contain numbers and letters.',
            sameAs: `Must be equal to ${upperFirst(params?.sameAs?.eq)}.`,
            isPositive: 'Value must be greater than 0.'
        };

        return messagesTable[key] || 'Something is wrong... 🤔';
    }
}

export default GetValidationErrorMessageHandler;
