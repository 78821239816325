<template>
    <div class="layout-wrapper">
        <navbar v-if="$auth.user" />

        <main class="flex">
            <sidebar v-if="$auth.user" />

            <nuxt />
        </main>
    </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar';
import Sidebar from '@/components/layout/Sidebar';

export default {
    components: {
        Navbar,
        Sidebar
    },

    middleware: 'auth'
};
</script>
