const dayjs = require('dayjs');

export const defaultState = () => {};

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    getLogsPerPeriod(ctx, { startDate, endDate }) {
        const params = {
            startDate,
            endDate: dayjs(endDate)
                .add(1, 'day')
                .toDate()
        };

        return this.$axios.$get('/users/logs-per-period', {
            params
        });
    },

    contactSupport(ctx, { firstName, lastName, email, message }) {
        return this.$axios.$post('/contact-support', {
            firstName,
            lastName,
            email,
            message
        });
    },

    getMeters(
        ctx,
        {
            currentPage = 1,
            perPage = 12,
            search = '',
            sortBy = ['createdAt:DESC']
        }
    ) {
        const params = {
            page: currentPage,
            perPage,
            q: search,
            sortBy
        };

        return this.$axios.$get('/users/meters', { params });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
