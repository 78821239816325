import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_bootstrapvue_6d82607a from 'nuxt_plugin_bootstrapvue_6d82607a' // Source: ./bootstrap-vue.js (mode: 'all')
import nuxt_plugin_sentryserver_d7d09d50 from 'nuxt_plugin_sentryserver_d7d09d50' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_2e5e3fd0 from 'nuxt_plugin_sentryclient_2e5e3fd0' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_portalvue_cd4ef982 from 'nuxt_plugin_portalvue_cd4ef982' // Source: ./portal-vue.js (mode: 'all')
import nuxt_plugin_dayjsplugin_6d4864c9 from 'nuxt_plugin_dayjsplugin_6d4864c9' // Source: ./dayjs-plugin.js (mode: 'all')
import nuxt_plugin_libplugin47522b64_5fc117ec from 'nuxt_plugin_libplugin47522b64_5fc117ec' // Source: ./lib.plugin.47522b64.js (mode: 'all')
import nuxt_plugin_axios_eaaa74d8 from 'nuxt_plugin_axios_eaaa74d8' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_74a28588 from 'nuxt_plugin_cookieuniversalnuxt_74a28588' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_fullbackgroundimagepath_5275c866 from 'nuxt_plugin_fullbackgroundimagepath_5275c866' // Source: ../../plugins/directives/full-background-image-path (mode: 'client')
import nuxt_plugin_fullimagepath_cd8d9d80 from 'nuxt_plugin_fullimagepath_cd8d9d80' // Source: ../../plugins/directives/full-image-path (mode: 'client')
import nuxt_plugin_formatdate_306f307a from 'nuxt_plugin_formatdate_306f307a' // Source: ../../plugins/filters/format-date (mode: 'all')
import nuxt_plugin_yearonly_095ac172 from 'nuxt_plugin_yearonly_095ac172' // Source: ../../plugins/filters/year-only (mode: 'all')
import nuxt_plugin_startcase_e46fbb76 from 'nuxt_plugin_startcase_e46fbb76' // Source: ../../plugins/filters/start-case (mode: 'all')
import nuxt_plugin_globalcomponents_91a8526c from 'nuxt_plugin_globalcomponents_91a8526c' // Source: ../../plugins/global-components (mode: 'all')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../../plugins/axios (mode: 'all')
import nuxt_plugin_dayjs_66ce5f68 from 'nuxt_plugin_dayjs_66ce5f68' // Source: ../../plugins/dayjs.js (mode: 'all')
import nuxt_plugin_modals_1d9973ca from 'nuxt_plugin_modals_1d9973ca' // Source: ../../plugins/modals.js (mode: 'all')
import nuxt_plugin_downloadfile_c94dd3a8 from 'nuxt_plugin_downloadfile_c94dd3a8' // Source: ../../plugins/download-file.js (mode: 'all')
import nuxt_plugin_httpheaders_34c4dce0 from 'nuxt_plugin_httpheaders_34c4dce0' // Source: ../../plugins/http-headers.js (mode: 'server')
import nuxt_plugin_vuelidate_4be431c8 from 'nuxt_plugin_vuelidate_4be431c8' // Source: ../../plugins/vuelidate.js (mode: 'all')
import nuxt_plugin_getvalidationerrormessagehandler_a3def902 from 'nuxt_plugin_getvalidationerrormessagehandler_a3def902' // Source: ../../plugins/get-validation-error-message-handler.js (mode: 'all')
import nuxt_plugin_vuetoasted_f6148aee from 'nuxt_plugin_vuetoasted_f6148aee' // Source: ../../plugins/vue-toasted (mode: 'client')
import nuxt_plugin_getservererrors_ee5c8aa2 from 'nuxt_plugin_getservererrors_ee5c8aa2' // Source: ../../plugins/get-server-errors.js (mode: 'client')
import nuxt_plugin_watchall_d7fc4c2c from 'nuxt_plugin_watchall_d7fc4c2c' // Source: ../../plugins/watch-all.js (mode: 'client')
import nuxt_plugin_bootstrapvue_7a18ce66 from 'nuxt_plugin_bootstrapvue_7a18ce66' // Source: ../../plugins/bootstrap-vue.js (mode: 'client')
import nuxt_plugin_vueapexcharts_7410a43a from 'nuxt_plugin_vueapexcharts_7410a43a' // Source: ../../plugins/vue-apexcharts.js (mode: 'client')
import nuxt_plugin_vuedatepicker_3b882067 from 'nuxt_plugin_vuedatepicker_3b882067' // Source: ../../plugins/vue-date-picker.js (mode: 'client')
import nuxt_plugin_main_a318cec6 from 'nuxt_plugin_main_a318cec6' // Source: ../../static/scripts/main.js (mode: 'client')
import nuxt_plugin_plugin_3fb75860 from 'nuxt_plugin_plugin_3fb75860' // Source: ./auth/plugin.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Client Portal | Unique Technologies","htmlAttrs":{"dir":"ltr"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.png"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_bootstrapvue_6d82607a === 'function') {
    await nuxt_plugin_bootstrapvue_6d82607a(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_d7d09d50 === 'function') {
    await nuxt_plugin_sentryserver_d7d09d50(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_2e5e3fd0 === 'function') {
    await nuxt_plugin_sentryclient_2e5e3fd0(app.context, inject)
  }

  if (typeof nuxt_plugin_portalvue_cd4ef982 === 'function') {
    await nuxt_plugin_portalvue_cd4ef982(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjsplugin_6d4864c9 === 'function') {
    await nuxt_plugin_dayjsplugin_6d4864c9(app.context, inject)
  }

  if (typeof nuxt_plugin_libplugin47522b64_5fc117ec === 'function') {
    await nuxt_plugin_libplugin47522b64_5fc117ec(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_eaaa74d8 === 'function') {
    await nuxt_plugin_axios_eaaa74d8(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_74a28588 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_74a28588(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fullbackgroundimagepath_5275c866 === 'function') {
    await nuxt_plugin_fullbackgroundimagepath_5275c866(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fullimagepath_cd8d9d80 === 'function') {
    await nuxt_plugin_fullimagepath_cd8d9d80(app.context, inject)
  }

  if (typeof nuxt_plugin_formatdate_306f307a === 'function') {
    await nuxt_plugin_formatdate_306f307a(app.context, inject)
  }

  if (typeof nuxt_plugin_yearonly_095ac172 === 'function') {
    await nuxt_plugin_yearonly_095ac172(app.context, inject)
  }

  if (typeof nuxt_plugin_startcase_e46fbb76 === 'function') {
    await nuxt_plugin_startcase_e46fbb76(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponents_91a8526c === 'function') {
    await nuxt_plugin_globalcomponents_91a8526c(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_dayjs_66ce5f68 === 'function') {
    await nuxt_plugin_dayjs_66ce5f68(app.context, inject)
  }

  if (typeof nuxt_plugin_modals_1d9973ca === 'function') {
    await nuxt_plugin_modals_1d9973ca(app.context, inject)
  }

  if (typeof nuxt_plugin_downloadfile_c94dd3a8 === 'function') {
    await nuxt_plugin_downloadfile_c94dd3a8(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_httpheaders_34c4dce0 === 'function') {
    await nuxt_plugin_httpheaders_34c4dce0(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelidate_4be431c8 === 'function') {
    await nuxt_plugin_vuelidate_4be431c8(app.context, inject)
  }

  if (typeof nuxt_plugin_getvalidationerrormessagehandler_a3def902 === 'function') {
    await nuxt_plugin_getvalidationerrormessagehandler_a3def902(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetoasted_f6148aee === 'function') {
    await nuxt_plugin_vuetoasted_f6148aee(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_getservererrors_ee5c8aa2 === 'function') {
    await nuxt_plugin_getservererrors_ee5c8aa2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_watchall_d7fc4c2c === 'function') {
    await nuxt_plugin_watchall_d7fc4c2c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_bootstrapvue_7a18ce66 === 'function') {
    await nuxt_plugin_bootstrapvue_7a18ce66(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueapexcharts_7410a43a === 'function') {
    await nuxt_plugin_vueapexcharts_7410a43a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuedatepicker_3b882067 === 'function') {
    await nuxt_plugin_vuedatepicker_3b882067(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_main_a318cec6 === 'function') {
    await nuxt_plugin_main_a318cec6(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_3fb75860 === 'function') {
    await nuxt_plugin_plugin_3fb75860(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
