import dayjs from 'dayjs';

export const defaultState = () => {};

export const state = () => defaultState();

export const getters = {};

export const mutations = {};

export const actions = {
    index(
        ctx,
        {
            currentPage = 1,
            perPage = 12,
            search = '',
            sortBy = ['createdAt:DESC'],
            filters = null
        } = {}
    ) {
        const params = {
            page: currentPage,
            perPage,
            q: search,
            sortBy
        };

        if (filters) {
            params.filters = JSON.stringify(filters);
        }

        return this.$axios.$get('/billings', {
            params
        });
    },

    generatePdf(ctx, id) {
        return this.$axios.$get(`/billings/${id}/generate-pdf`, {
            responseType: 'arraybuffer'
        });
    },

    getBillingPerPeriod(ctx, { fromDate, toDate }) {
        const params = {
            fromDate,
            toDate: dayjs(toDate)
                .add(1, 'day')
                .toDate()
        };

        return this.$axios.$get('/billings/per-period', {
            params
        });
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
