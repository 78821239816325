<template>
    <div class="b_login">
        <div class="login_form_wrapper">
            <div class="login_form_inner">
                <nuxt />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState('auth', ['loggedIn'])
    },

    watch: {
        loggedIn: {
            handler() {
                if (this.loggedIn) {
                    const redirect =
                        this.$auth.$storage.getCookie('redirect') || '/';

                    this.$auth.$storage.setCookie('redirect', null, false);

                    this.$router.push(redirect);
                }
            },
            immediate: true
        }
    }
};
</script>
