<template>
    <div class="b_left_sidebar" :class="{ 'show-sidebar': isSidebarOpen }">
        <div class="sidebar_head">
            <div class="head_inner flex ai_center jc_between">
                <div class="logo_block">
                    <img v-full-image-path:assets="'logo_text.png'" />
                </div>
                <div class="close_wrapper">
                    <button
                        class="btn_reset btn_close"
                        type="button"
                        @click="toggleSidebar"
                    />
                </div>
            </div>
        </div>
        <div class="sidebar_inner">
            <div v-if="$auth.user" class="nav_menu">
                <div class="menu_item">
                    <nuxt-link class="menu_link" to="/billings">
                        <span class="icon">
                            <span class="icon_static">
                                <img
                                    v-full-image-path:assets="
                                        'icon_billings_static.svg'
                                    "
                                />
                            </span>
                            <span class="icon_hover">
                                <img
                                    v-full-image-path:assets="
                                        'icon_billings_hover.svg'
                                    "
                                />
                            </span>
                        </span>
                        <span class="title">Billings</span>
                    </nuxt-link>
                </div>

                <div class="menu_item">
                    <nuxt-link class="menu_link" to="/usage">
                        <span class="icon">
                            <span class="icon_static">
                                <img
                                    v-full-image-path:assets="
                                        'icon_trade_usage_static.svg'
                                    "
                                />
                            </span>
                            <span class="icon_hover">
                                <img
                                    v-full-image-path:assets="
                                        'icon_trade_usage_hover.svg'
                                    "
                                />
                            </span>
                        </span>
                        <span class="title">Usage</span>
                    </nuxt-link>
                </div>

                <div class="menu_item">
                    <nuxt-link class="menu_link" to="/contact-us">
                        <span class="icon">
                            <span class="icon_static">
                                <img
                                    v-full-image-path:assets="
                                        'icon_contact_us_static.svg'
                                    "
                                />
                            </span>
                            <span class="icon_hover">
                                <img
                                    v-full-image-path:assets="
                                        'icon_contact_us_hover.svg'
                                    "
                                />
                            </span>
                        </span>
                        <span class="title">Contact us</span>
                    </nuxt-link>
                </div>

                <div class="menu_item">
                    <nuxt-link class="menu_link" to="/payments">
                        <span class="icon">
                            <span class="icon_static">
                                <img
                                    v-full-image-path:assets="
                                        'icon_payments_static.svg'
                                    "
                                />
                            </span>
                            <span class="icon_hover">
                                <img
                                    v-full-image-path:assets="
                                        'icon_payments_hover.svg'
                                    "
                                />
                            </span>
                        </span>
                        <span class="title">Payments</span>
                    </nuxt-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            isSidebarOpen: 'layout/isSidebarOpen'
        })
    },

    methods: {
        ...mapActions({
            toggleSidebar: 'layout/toggleSidebar'
        })
    }
};
</script>
