export const defaultState = () => {
    return {
        isSidebarOpen: false
    };
};

export const state = () => defaultState();

export const getters = {
    isSidebarOpen: state => state.isSidebarOpen
};

export const mutations = {
    TOGGLE_SIDEBAR(state) {
        state.isSidebarOpen = !state.isSidebarOpen;
    }
};

export const actions = {
    toggleSidebar({ commit }) {
        commit('TOGGLE_SIDEBAR');
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
