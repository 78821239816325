import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5a095899 = () => interopDefault(import('../../pages/billings/index.vue' /* webpackChunkName: "pages/billings/index" */))
const _9bb4c528 = () => interopDefault(import('../../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _5b2e5220 = () => interopDefault(import('../../pages/forget-password/index.vue' /* webpackChunkName: "pages/forget-password/index" */))
const _097df6de = () => interopDefault(import('../../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _3f86b42e = () => interopDefault(import('../../pages/payments/index.vue' /* webpackChunkName: "pages/payments/index" */))
const _51a7c1d4 = () => interopDefault(import('../../pages/usage/index.vue' /* webpackChunkName: "pages/usage/index" */))
const _5db600c6 = () => interopDefault(import('../../pages/payments/checkout/index.vue' /* webpackChunkName: "pages/payments/checkout/index" */))
const _0e6df8ca = () => interopDefault(import('../../pages/payments/thank-you/index.vue' /* webpackChunkName: "pages/payments/thank-you/index" */))
const _61bbbfbc = () => interopDefault(import('../../pages/reset-password/_token.vue' /* webpackChunkName: "pages/reset-password/_token" */))
const _06c17635 = () => interopDefault(import('../../pages/set-password/_token.vue' /* webpackChunkName: "pages/set-password/_token" */))
const _688fed84 = () => interopDefault(import('../../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/billings",
    component: _5a095899,
    name: "billings"
  }, {
    path: "/contact-us",
    component: _9bb4c528,
    name: "contact-us"
  }, {
    path: "/forget-password",
    component: _5b2e5220,
    name: "forget-password"
  }, {
    path: "/login",
    component: _097df6de,
    name: "login"
  }, {
    path: "/payments",
    component: _3f86b42e,
    name: "payments"
  }, {
    path: "/usage",
    component: _51a7c1d4,
    name: "usage"
  }, {
    path: "/payments/checkout",
    component: _5db600c6,
    name: "payments-checkout"
  }, {
    path: "/payments/thank-you",
    component: _0e6df8ca,
    name: "payments-thank-you"
  }, {
    path: "/reset-password/:token?",
    component: _61bbbfbc,
    name: "reset-password-token"
  }, {
    path: "/set-password/:token?",
    component: _06c17635,
    name: "set-password-token"
  }, {
    path: "/",
    component: _688fed84,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
