const $ = require('jquery');

window.addEventListener('pageshow', ({ persisted }) => {
    if (persisted) {
        window.location.reload();
    }
});

window.addEventListener('unload', () => {});

window.jQuery = $;
