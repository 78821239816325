'use strict';

export default (ctx, inject) => {
    inject('downloadFile', (href, name, blank = false) => {
        const fileLink = document.createElement('a');

        fileLink.href = href;
        fileLink.setAttribute('download', name);

        if (blank) {
            fileLink.setAttribute('target', '_blank');
        }

        fileLink.click();
    });
};
