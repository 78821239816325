import Vue from 'vue';
import Toasted from 'vue-toasted';

Vue.use(Toasted, {
    duration: 3000,
    position: 'bottom-right',
    theme: 'bubble'
});

Vue.toasted.register(
    'error',
    payload => {
        const { message } = payload;

        if (!message) {
            return 'Something went wrong!';
        }

        return message;
    },
    {
        type: 'error'
    }
);
