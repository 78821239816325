export default ({ store: { dispatch, getters } }, inject) => {
    inject('showModal', modal => getters['modals/show'](modal));

    inject('openModal', (name, data = null) => {
        dispatch('modals/open', {
            name,
            data
        });
    });

    inject('closeModal', () => dispatch('modals/close'));
};
