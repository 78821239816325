<template>
    <header class="b_header">
        <div class="header_wrapper flex ai_center">
            <div class="menu_button">
                <button
                    class="menu_toggler jsMenu"
                    type="button"
                    @click="toggleSidebar"
                />
            </div>

            <div class="action-wrapper">
                <div class="header_logo">
                    <div class="logo_link">
                        <img v-full-image-path:assets="'logo.png'" />
                    </div>
                </div>

                <button class="btn btn-secondary ml-2" @click="logout">
                    Logout
                </button>
            </div>
        </div>
    </header>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions({
            toggleSidebar: 'layout/toggleSidebar'
        }),

        async logout() {
            try {
                await this.$auth.logout();

                this.$router.push('/login');
                this.$toasted.success('Logged out!');
            } catch (error) {
                console.error(error);

                this.$sentry.captureException(error);

                this.$toasted.global.error();
            }
        }
    }
};
</script>
